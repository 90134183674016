@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  font-family: "Quicksand", sans-serif !important;
}
/* card-slider borderttt rounded-md */
.bgsub{

  background-image: url(https://i.ibb.co/fvyh69D/layer7.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.borderttt {
    border-image: linear-gradient(#00E7D9, #330076c4, #fb00ffe9) 30;
    border-width: 2px;
    border-style: solid;
    padding: 10px;
    /* width: 185px; */
}
.reactjs{
	background: -webkit-linear-gradient(0deg, #FFFFFF, #032AF9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.pythonjs{
	background: -webkit-linear-gradient(0deg, #2876B6, #FCD025);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.nodejs{
	background: -webkit-linear-gradient(0deg, #FFFFFF, #6CC04A);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.sqljs{
	background: -webkit-linear-gradient(0deg, #FFFFFF, #032AF9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.awsjs{
	background: -webkit-linear-gradient(0deg, #FFFFFF, #032AF9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
 
.ght{ background: linear-gradient(159.31deg,rgba(52, 0, 118, 0.78) 49.27%, rgba(251, 0, 255, 0.545) 99.03%), url("https://i.ibb.co/gSsbnCc/layer9.png") no-repeat right top;
background-color: #2f0035;
}

.ggt{
  background: url('https://i.ibb.co/61xYNvL/Vector-7.png') no-repeat ;
  background-size: contain;
  background-position: bottom;
}
.ggt2{
  background: url('https://i.ibb.co/jrTc7fM/layer2.png') no-repeat ;
  background-size: contain;
  background-position: bottom;
}
.bgcolor{
  background-color: rgba(3, 3, 3, 0.822);
}
.bgcolorpartner{
  background-color: rgb(236, 236, 236);
}

/* *, *::before, *::after {
	box-sizing: border-box;
}

@keyframes rotate {
	100% {
		transform: rotate(1turn);
	}
}

.rainbow {
	position: relative;
	z-index: 0; 
	border-radius: 10px;
	overflow: hidden;
	padding: 2rem;
	
	&::before {
		content: '';
		position: absolute;
		z-index: -2;
		left: -50%;
		top: -50%;
		width: 200%;
		height: 200%;
		background-color: #399953;
		background-repeat: no-repeat;
		background-size: 50% 50%, 50% 50%;
		background-position: 0 0, 100% 0, 100% 100%, 0 100%;
		background-image: linear-gradient(#399953, #399953), linear-gradient(#fbb300, #fbb300), linear-gradient(#d53e33, #d53e33), linear-gradient(#377af5, #377af5);
		animation: rotate 4s linear infinite;
	}
	
	&::after {
		content: '';
		position: absolute;
		z-index: -1;
		left: 6px;
		top: 6px;
		width: calc(100% - 12px);
		height: calc(100% - 12px);
		background: white;
		border-radius: 5px;
		animation: opacityChange 3s infinite alternate;
	}
}

@keyframes opacityChange {
	50% {
		opacity:1;
	}
	100% {
		opacity: .5;
	}
} */

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}
 
.rainbow { 
  position: relative;
  background: #3b0808;
  padding: 4px;
  background-color: black;
}
.rainbow::before,
.rainbow::after {
  content: "";
  position: absolute;
  inset: -0.2rem;
  z-index: -1;
  background: linear-gradient(var(--angle), 
    #032146,  #C3F2FF, #b00);
  animation: rotate 10s linear infinite;
}
.rainbow::after {
  filter: blur(10px);
}
@keyframes rotate {
  0%     { --angle: 0deg; }
  100%   { --angle: 360deg;
  }
}





/* glowinf css  */
/* Add a border to the button */
.myButton {
  border: 2px solid #333; /* Initial border color */
  padding: 10px 20px; /* Optional: Add padding for better appearance */
  animation: glow 5s infinite alternate; /* Apply the glow animation */
}

/* Define the glow animation */
@keyframes glow {
  0% {
      border-color: #ff000037; /* Red */
  }
  20% {
      border-color: #ff990041; /* Orange */
  }
  40% {
      border-color: #ffff002b; /* Yellow */
  }
  60% {
      border-color: #33cc3325; /* Green */
  }
  80% {
      border-color: #3366ff19; /* Blue */
  }
  100% {
      border-color: rgba(0, 204, 255, 0.335); /* Purple */
  }
}






.swiper {
  width: 240px;
  height: 320px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}

.swiper-slide:nth-child(1n) {
  background-color: rgb(206, 17, 17);
}
.card {
  transition: transform 0.3s ease, background-color 0.3s ease;
  
}

.hoverbg {
  transform: scale(1.2);
  z-index: 1000;

}

.slick-slide > div {
  margin: 0 10px; /* Adjust margin for large devices */
}
