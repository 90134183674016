.gtco-testimonials {
    position: relative; 
  }
  @media (max-width: 767px) {
    .gtco-testimonials {
      margin-top: 20px;
    }
  }
  .gtco-testimonials h2 {
    font-size: 30px;
    text-align: center;
    margin-bottom: 50px;
    color: #FFFFFF;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    letter-spacing: 2px;
    background-color:black;
    padding:10px 5px;
  }
  .gtco-testimonials .owl-stage-outer {
    padding: 30px 0;
  }
  .gtco-testimonials .owl-nav {
    display: none;
  }
  .gtco-testimonials .owl-dots {
    text-align: center;
  }
  .gtco-testimonials .owl-dots span {
    position: relative;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: block;
    background: #fff;
    border: 2px solid #01b0f8;
    margin: 0 5px;
  }
  .gtco-testimonials .owl-dots .active {
    box-shadow: none;
  }
  .gtco-testimonials .owl-dots .active span {
    background: #01b0f8;
    box-shadow: none;
    height: 12px;
    width: 12px;
    margin-bottom: -1px;
  }
  .gtco-testimonials .card {
    background: #fff;
    box-shadow: 0 8px 30px -7px #c9dff0;
    margin: 0 20px;
    padding: 0 10px;
    border-radius: 20px;
    border: 0;
  }
  .gtco-testimonials .card .card-img-top {
    max-width: 100px;
    border-radius: 50%;
    margin: 15px auto 0;
    box-shadow: 0 8px 20px -4px #95abbb;
    width: 100px;
    height: 100px;
  }
  .gtco-testimonials .card h5 {
    color: #01b0f8;
    font-size: 21px;
    line-height: 1.3;
  }
  .gtco-testimonials .card h5 span {
    font-size: 18px;
    color: #666666;
  }
  .gtco-testimonials .card p {
    font-size: 18px;
    color: #555;
    padding-bottom: 15px;
  }
  .gtco-testimonials .active {
    opacity: 0.5;
    transition: all 0.3s;
  }
  .gtco-testimonials .center {
    opacity: 1;
  }
  .gtco-testimonials .center h5 {
    font-size: 24px;
  }
  .gtco-testimonials .center h5 span {
    font-size: 20px;
  }
  .gtco-testimonials .center .card-img-top {
    max-width: 100%;
    height: 120px;
    width: 120px;
  }
  .owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
    outline: 0;
  }
  